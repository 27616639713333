@import "../../assets/sass/index";
.BtnRound {
  @include getButtonsAHF($cWhite);
  @include getOutlineFocus(4px, 2px);
  border-radius: 50px;
  width: min-content;
  text-transform: uppercase;
  // line-height: 100%;
  text-align: center;
  position: relative;
  font-weight: 600;
  border-width: 2px;
  border-style: solid;
  border-color: $cPrimary10;
  //
  min-width: 150px;
  width: max-content;
  font-size: 13.417px;
  padding: 10px 55px;
  line-height: 20.126px; /* 150% */
  letter-spacing: 0.168px;
  &[disabled] {
    cursor: initial;
    &,
    &:active,
    &:focus,
    &:visited,
    &:hover {
      opacity: 0.2;
      color: $cWhite;
      background-color: $cPrimary10;
    }
  }
  @include media($sm) {
    font-size: 13.417px;
    padding: 10px 55px;
  }
  &.CenterBottom {
    margin: auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    bottom: 0;
  }
  &.Center {
    margin-left: auto;
    margin-right: auto;
  }
  &.Full {
    width: 100%;
  }
  &.Secondary {
    @include getButtonsAHF(
      $cPrimary10,
      $cPrimaryLight05,
      transparent,
      $cPrimary10,
      $cPrimaryLight10
    );
    &.Outline {
      @include getButtonsAHF(
        $cPrimary10,
        $cWhite,
        $cPrimaryLight10,
        $cPrimary10,
        $cWhite,
        $cPrimaryLight15
      );
    }
    &.isDone {
      @include getButtonsAHF(
        $cPrimary10,
        $cWhite,
        $cPrimaryLight10,
        $cPrimary10,
        $cWhite,
        $cPrimaryLight15
      );
    }
  }
  &.Borderless {
    @include getButtonsAHF($cWhite, $cPrimaryLight15);
    border-color: transparent !important;
  }
  &.Outline {
    @include getButtonsAHF(
      $cPrimary10,
      $cWhite,
      $cPrimary10,
      $cPrimary10,
      $cPrimaryLight05
    );
  }
  &.isDone {
    @include getButtonsAHF(
      $cPrimary10,
      $cWhite,
      $cPrimaryLight10,
      $cPrimary10,
      $cWhite,
      $cPrimaryLight15
    );
  }
  &.ALink {
    @include getButtonsAHF(
      $cPrimary10,
      transparent,
      transparent,
      $cPrimaryLight15,
      transparent
    );
    padding: 0;
    font-size: inherit;
    text-transform: initial;
    border: none;
    &::before {
      outline: none;
    }
  }
  &.Small {
    font-size: 1em;
    min-width: 100px;
    padding: 5px 15px;
  }
  &.ExtraSmall {
    font-size: 0.9em;
    min-width: 100px;
    padding: 5px 15px;
  }
  &.Huge {
    font-size: 1.5em;
  }
  .IconL {
    margin-right: 5px;
  }
  .Icon {
    margin-left: 5px;
  }
  &.OnlyIcon {
    min-width: 25px;
    svg {
      margin-left: 0;
      margin-right: 0;
    }
    &.Small {
      font-size: 1em;
      min-width: 50px;
      padding: 5px 15px;
    }
    &.ExtraSmall {
      font-size: 0.9em;
      min-width: 50px;
      padding: 5px 15px;
    }
  }
  &.RightButton {
    margin-left: auto;
    margin-right: auto;
    padding: 5px 15px;
    @include media($sm) {
      right: 0px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
    @include media($md) {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
    }
    @include media($lg) {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.BtnRoundSquare {
  @include getButtonsAHF($cWhite);
  @include getOutlineFocus(4px, 2px);
  border-radius: 10px;
  width: min-content;
  text-transform: uppercase;
  // line-height: 100%;
  text-align: center;
  position: relative;
  font-weight: 600;
  border-width: 2px;
  border-style: solid;
  border-color: $cPrimary10;
  //
  min-width: 320px;
  width: max-content;
  font-size: 13.417px;
  padding: 10px 55px;
  line-height: 20.126px; /* 150% */
  letter-spacing: 0.168px;
  flex-direction: column;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    // flex-direction: row;
    margin: 0px 0px 0px 25px;
  }
  @media (min-width: 1440px) {
    height: 43px;
  }
  @media (min-width: 1920px) {
    height: 57px;
  }
  &[disabled] {
    cursor: initial;
    &,
    &:active,
    &:focus,
    &:visited,
    &:hover {
      opacity: 0.2;
      color: $cWhite;
      background-color: $cPrimary10;
    }
  }
  @include media($sm) {
    font-size: 13.417px;
    padding: 10px 55px;
  }
  &.CenterBottom {
    margin: auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    bottom: 0;
  }
  &.Center {
    margin-left: auto;
    margin-right: auto;
  }
  &.Full {
    width: 100%;
  }
  &.Secondary {
    @include getButtonsAHF(
      $cPrimary10,
      $cPrimaryLight05,
      transparent,
      $cPrimary10,
      $cPrimaryLight10
    );
    &.Outline {
      @include getButtonsAHF(
        $cPrimary10,
        $cWhite,
        $cPrimaryLight10,
        $cPrimary10,
        $cWhite,
        $cPrimaryLight15
      );
    }
    &.isDone {
      @include getButtonsAHF(
        $cPrimary10,
        $cWhite,
        $cPrimaryLight10,
        $cPrimary10,
        $cWhite,
        $cPrimaryLight15
      );
    }
  }
  &.Borderless {
    @include getButtonsAHF($cWhite, $cPrimaryLight15);
    border-color: transparent !important;
  }
  &.Outline {
    @include getButtonsAHF(
      $cPrimary10,
      $cWhite,
      $cPrimary10,
      $cPrimary10,
      $cPrimaryLight05
    );
  }
  &.isDone {
    @include getButtonsAHF(
      $cPrimary10,
      $cWhite,
      $cPrimaryLight10,
      $cPrimary10,
      $cWhite,
      $cPrimaryLight15
    );
  }
  &.ALink {
    @include getButtonsAHF(
      $cPrimary10,
      transparent,
      transparent,
      $cPrimaryLight15,
      transparent
    );
    padding: 0;
    font-size: inherit;
    text-transform: initial;
    border: none;
    &::before {
      outline: none;
    }
  }
  &.Small {
    font-size: 1em;
    min-width: 100px;
    padding: 5px 15px;
  }
  &.ExtraSmall {
    font-size: 0.9em;
    min-width: 100px;
    padding: 5px 15px;
  }
  &.Huge {
    font-size: 1.5em;
  }
  .IconL {
    margin-right: 5px;
  }
  .Icon {
    margin-left: 5px;
  }
  &.OnlyIcon {
    min-width: 25px;
    svg {
      margin-left: 0;
      margin-right: 0;
    }
    &.Small {
      font-size: 1em;
      min-width: 50px;
      padding: 5px 15px;
    }
    &.ExtraSmall {
      font-size: 0.9em;
      min-width: 50px;
      padding: 5px 15px;
    }
  }
  &.RightButton {
    margin-left: auto;
    margin-right: auto;
    padding: 5px 15px;
    @include media($sm) {
      right: 0px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
    @include media($md) {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
    }
    @include media($lg) {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.CalendarIcon {
  border: 1px solid;
  padding: 1.5% 2.5%;
  background-color: $cPrimary10;
  color: $cPrimaryLight05;
  display: flex;
  margin-top: 80px;
  margin-bottom: 10px;
  margin-left: auto;
  position: absolute;
  right: 0;
  @include media($sm) {
    padding: 0.5% 1%;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
  }
  @include media($md) {
    padding: 0.5% 1%;
    margin-top: 10px;
  }
  @include media($lg) {
    padding: 0.5% 1%;
    margin-top: 10px;
  }
}
