@import "../../../assets/sass/index";

.InputArea {
  width: 100%;
  position: relative;
  margin: 0 0 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &.InFocus {
    .Input {
      border: 1px solid $cPrimary10;
    }
    label {
      color: $cPrimary10;
    }
    .WrapperInput {
      box-shadow: $boxShadowInputs;
      border-radius: 50px;
    }
  }

  label {
    border-radius: 5px;
    color: $cGrey;
    position: absolute;
    left: 15px;
    top: -0.5em;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    font-size: 0.8em;
    background-color: $cWhite;
  }
}

.Input {
  margin: 0;
  font-size: 1em;
  line-height: 100%;
  border: 1px solid $cGreyLight;
  border-radius: 50px;
  width: 100%;
  padding: 10px 15px;
  span {
    width: 100%;
    display: inline-block;
    overflow: hidden;
  }
  svg {
    font-size: 10px;
  }
  svg + svg {
    margin-left: 5px;
  }
  &.ShowBtn {
    padding: 10px 40px 10px 15px;
  }
  &:focus {
    outline: none;
  }
}

.Wrapper {
  position: relative;
  width: 100%;
  &Focus {
    position: relative;
    width: 100%;
    @include getOutline;
  }
}
.BtnShowPW {
  @include getButtonsAHF(
    $cPrimary10,
    transparent,
    transparent,
    $cPrimaryLight05,
    $cPrimaryLight15
  );
  position: absolute;
  right: 5px;
  top: 50%;
  font-size: 1.2rem;
  transform: translateY(-50%);
  background: transparent;
  border-radius: 100%;
  outline: none;
  border: 0;
}
