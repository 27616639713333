.Alert {
  position: relative;
  padding: 15px 30px 15px 25px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: left;

  &.Mt {
    margin-top: 1rem;
  }
  &.Warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  &.Danger {
    color: #850404;
    background-color: #ffcdcd;
    border-color: #ffbabaef;
  }
  &.Success {
    color: #048515;
    background-color: #baffc5;
    border-color: #baffcb;
  }
  &.Info {
    color: #146d88;
    background-color: #e3fffe;
    border-color: #c9eff1;
  }
  &.Absolute {
    z-index: 995;
    position: absolute;
    width: calc(100% - 30px);
  }

  .Close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 0.4em;
    color: inherit;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    opacity: 0.5;
    text-shadow: 0 1px 0 #fff;
  }
}
