@import "../../assets/sass/index";
.ScrollToTop {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 10px;
  right: 10px;
  border-radius: 100%;
  background-color: lighten($cPrimary10, 10%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  padding: 0;
  margin: 0;
  transition: 500ms;
  opacity: 0;
  color: $cWhite;
  transform: translate(200px, 200px);
  z-index: 9999;
  cursor: pointer;
  outline: none;
  &.Visible {
    opacity: 0.8;
    transform: translate(0px, -50px);
    @include media($sm) {
      opacity: 0.5;
    }
  }

  &:focus {
    box-shadow: $boxShadowInputs;
    background-color: $cPrimaryLight15;
  }
  &:hover,
  &:active {
    opacity: 0.9 !important;
    background-color: $cPrimaryLight15;
  }
  @include getOutlineFocus(8px, 4px);
}
