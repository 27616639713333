@import "../../assets/sass/index";
.WaterMarkProbrain {
  position: fixed;
  width: 100px;
  height: 40px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  padding: 0;
  margin: 0;
  transition: 500ms;
  opacity: 0;
  color: $cWhite;
  outline: none;
  opacity: 0.2;
  z-index: 99999;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 25px;

  img {
    z-index: 99;
    height: auto;
    width: 100%;
  }
}
