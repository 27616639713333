.Error,
.Ok {
  svg {
    margin-right: 5px;
  }
}
.Error {
  color: red;
}

.Ok {
  color: green;
}
.PassWordValidations {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 25px;
  text-align: left;
  p {
    margin-bottom: 10px;
  }
}
