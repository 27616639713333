// Sizes
$topNav: 3.5rem;
$paddingPage: 4.5rem;
$paddingMD: 3rem;
$paddingSM: 2rem;

// Midia queries
$sm: "576";
$md: "768";
$lg: "992";
$xl: "1200";

// To control initial font size.
$initialFontSize: 14px;

// Colors
$cPrimaryLight05: #e8faff;
$cPrimaryLight07: #dfeff5;
$cPrimaryLight10: #c1dde6;
$cPrimaryLight12: #7dc5dd;
$cPrimaryLight15: #188fa5;
$cPrimaryLight16: #f4f9fb;
$cPrimary10: #007085;
$cPrimaryDark: darken(#007085, 15%);

$cWhite: #fefefe;
$cGreyLighter: #f3f3f3;
$cGreyLighterDivision: #E0E0E0;
$cGreyLight: #cacaca;
$cGrey: #555555;
$cGreyDark: #383838;
$cBlack: #222222;

$cText: #222222;

// Box-Shadow
$boxShadowNav: 0 0 5px #55555533;
$boxShadowNav: 0 0 15px 0px #10101024;
$boxShadowNav: 0 0 15px -5px #00000040;
$boxShadow: 0 0 5px #55555533;
$boxShadowInputs: 0 0 5px $cPrimaryLight15 + "33";

$cDanger: #b74444;
$cDangerSoft: #bb6f6f;
$cSuccess: #008b0c;
$cInfo: #19cae9;

//  Z-Indexs
$zIndexModal: 999999;
$zIndexMainNav: 999;
$zIndexGoTop: 99;
$zIndexWatermark: 1;

// Transitions
$transition: 300ms;
