@mixin getListStyle() {
  list-style: none;
}

@mixin media($pixel) {
  @media screen and (min-width: $pixel + "px") {
    @content;
  }
}

@mixin media-max($pixel) {
  @media screen and (max-width: ($pixel - 1 )+ "px") {
    @content;
  }
}

@mixin getLinkAHF($color: $cPrimary10) {
  color: $color;
  text-decoration: none;
  &:focus {
    box-shadow: $boxShadow;
  }
  &:focus,
  &:hover,
  &:active {
    cursor: pointer;
    color: darken($color, 15%);
  }
}

@mixin getButtonsAHF(
  $color: $cWhite,
  $bg: $cPrimary10,
  $border: $bg,
  $overColor: $cWhite,
  $overBg: darken($cPrimary10, 5%),
  $overCBorder: $overBg
) {
  color: $color;
  background-color: $bg;
  border-color: $border;
  text-decoration: none;
  &:focus {
    box-shadow: $boxShadow;
  }
  &:focus,
  &:hover,
  &:active {
    cursor: pointer;
    color: $overColor;
    background-color: $overBg;
    border-color: $overCBorder;
  }
}

@mixin getSelectButton(
  $color: $cGreyLight,
  $bg: $cPrimary10,
  $border: $cBlack,
  $overColor: $cWhite,
  $overBg: darken($cWhite, 5%),
  $overCBorder: $overBg
) {
  color: $cBlack;
  background-color: $bg;
  border-color: $border;
  text-decoration: none;
  &:focus {
    box-shadow: $boxShadow;
  }
  &:focus,
  &:hover,
  &:active {
    cursor: pointer;
    color: $cBlack;
    font: 400 13.3333px Arial;
    color: $cGrey;
    border-color: $cPrimary10;
  }
}

.getOutline {
  border-radius: 50px;
  box-shadow: 0 0 7px 0 $cPrimary10;
  z-index: 0;
}

@mixin getOutline() {
  @extend .getOutline;
}
@mixin getOutlineFocus($border: 0, $borderCorrection: 0) {
  &:focus {
    outline: none !important;
    box-shadow: none;
    &::before {
      content: "";
      position: absolute;
      @extend .getOutline;
      width: calc(
        100% + #{$border}
      ); // 4px to overhide the 2px from the border - l&r
      height: calc(
        100% + #{$border}
      ); // 4px to overhide the 2px from the border - l&r
      top: -$borderCorrection; // -2px to overhide the 2px from the border
      left: -$borderCorrection; // -2px to overhide the 2px from the border
    }
  }
}
