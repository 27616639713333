@import "./assets/sass/fonts";
@import "./assets/sass/index";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
b,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
figure,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-family: "Montserrat", sans-serif;
}

html {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
body {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}

strong {
  font-weight: bold;
}

.App {
  text-align: left;
}

a {
  @include getLinkAHF($cPrimary10);
}

ul {
  padding: 0;
  list-style: none;
}

form {
  min-width: 250px;
  @include media($sm) {
    min-width: 300px;
  }
}

button {
  font-family: "Montserrat", sans-serif;
}
em {
  opacity: 0.8;
  font-size: 0.9em;
}
