// Light
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/montserrat/Montserrat-ExtraLight.ttf");
}

// Normal
@font-face {
  font-family: "Montserrat";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/montserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/montserrat/Montserrat-Regular.ttf");
}
// Bold
@font-face {
  font-family: "Montserrat";
  font-weight: bold;
  font-style: normal;
  src: url("../fonts/montserrat/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/montserrat/Montserrat-ExtraBold.ttf");
}
// SemiBold
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/montserrat/Montserrat-SemiBold.ttf");
}

// Bolder
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/montserrat/Montserrat-Black.ttf");
}
