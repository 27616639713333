@import "../../../assets/sass/index";

.Login {
  padding-bottom: $paddingPage;
  padding-top: $paddingPage;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
}

//
//
//
.TagCard {
  // Only Login
  margin-top: -5rem;
  //
  box-shadow: $boxShadow;
  padding: 25px 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1.5rem;
  max-width: 300px;
  min-width: 300px;
  margin-bottom: 75px;
  @include media($sm) {
    max-width: 350px;
    min-width: 350px;
  }
  a {
    text-align: center;
  }
}
.Logo {
  position: absolute;
  height: 7rem;
  width: 7rem;
  border-radius: 100%;
  z-index: 1;
  margin: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $cWhite;
  top: -2em;
  right: -3em;
  box-shadow: $boxShadow;
  padding: 10px 70px;
  @include media($sm) {
    right: -2em;
  }
}

.Title {
  text-align: left;
  font-weight: bolder;
  width: 100%;
  margin: 0;
  font-size: 1.8em;
  margin: 0 0 10px 0;
  display: inline-block;
  line-height: 100%;
}

.Subtitle {
  text-align: left;
  width: 100%;
  margin: 0;
  font-size: 1em;
  margin: 0 0 25px 0;
  display: inline-block;
  line-height: 100%;
}

.CheckArea {
  display: flex;
  justify-content: center;
  text-align: left;
  p {
    padding-left: 10px;
    margin: 0;
  }
}
.termsLogin {
  display: block;
  padding: 5px 5px;
  justify-content: center;
  text-align: center;
}
.termsContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 120%;
  align-items: center;
}

.SmallLinkLeft {
  font-size: 0.8em;
  margin-top: 10px;
  margin-left: auto;
  margin-right: 15px;
}
.NeedHelp {
  margin: 25px 0;
}
