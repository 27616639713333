@import "../../../assets/sass/index";
.MainNavArea {
  width: 100px;
}

.MainNav {
  box-shadow: $boxShadowNav;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: $zIndexMainNav;
  background-color: $cWhite;
  figure {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    flex: 1 1 auto;
    display: inline-block;
    margin: 0;
    // max-height: 3rem;
    // min-height: 3rem;
    height: 3rem;
  }
  img {
    height: 100%;
    width: auto;
    display:flex;
    margin: 0;
    padding: 0px;
  }
  button {
    z-index: $zIndexMainNav + 99;
    border: 0;
    background-color: transparent;
  }

  ul {
    display: flex;
  }
}
.InnerArea {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: $topNav;
  min-height: $topNav;
}

.Spacer {
  flex: 1 1 auto;
  width: 100%;
}

.AsideNav {
  z-index: $zIndexMainNav + 9;
  position: fixed;
  width: 0;
  max-width: 300px;
  height: 100vh;
  right: 0;
  // top: 2rem;
  transition: $transition;

  nav {
    // padding-top: 2rem;
    position: absolute;
    right: 0;
    top: 0;
    background-color: $cWhite;
    height: 100vh;
    width: 0;
    transition: $transition;
  }
  ul {
    @include getListStyle;
    display: none;
    margin: 0;
  }
  li + li {
    margin-top: 1.5rem;
  }
}

.OutsideArea {
  position: fixed;
  z-index: $zIndexMainNav - 9;
  left: 0;
  top: 0;
  width: 0px;
  height: 0px;

  div {
    width: 100%;
    height: 100%;

    opacity: 0;
    background-color: #2d2d2d2b;
  }
}
.AsideNavOpen {
  .AsideNav {
    width: 100%;
  }
  ul {
    
    display: block;
    overflow: hidden auto;
    height: 80vh;
    .menuContain{
      padding-top: 10px;
      padding-left: 2rem;
    }
  }
  nav {
    width: 100%;
  }
  .OutsideArea {
    width: 100%;
    height: 100vh;
    div {
      opacity: 1;
      transition: $transition;
    }
  }
}

.BtnOpenAside {
  // @include getLinkAHF($cPrimary10);
  // font-size: 1.5rem;
  color: $cPrimary10 !important;
  min-width: 50px;
 
}
.NavLabel {
  opacity: 0.8;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: bold;
}

.profile{
 
  span{
    font-size: 12px;
  
   
  }
  
  
}
.primary{
  background-color: $cPrimary10 !important;
}
