@import "./assets/sass/";

.react-datepicker__header,
.react-datepicker__month-text {
  font-size: 10px;
}
.react-datepicker-popper {
  z-index: 999999;
  text-transform: uppercase;
}
.react-datepicker__input-container {
  input {
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 15px;
    border: none;
    margin: 1rem 0;
    -webkit-box-shadow: 0 0 10px $cGrey;
    outline: none;
    background: transparent;
    &:focus {
      -webkit-box-shadow: 0 0 10px $cPrimary10;
    }
  }
}

#datepicker {
  -webkit-appearance: none;
  font-size: 16px;
  text-align: center;
  color: gray;
  padding: 10px 20px;
  border-radius: 15px;
  border: none;
  margin: 1rem 0;
  -webkit-box-shadow: 0 0 10px $cGrey;
  outline: none;
  background: transparent;
  &:focus {
    -webkit-box-shadow: 0 0 10px $cPrimary10;
  }
}

.react-datepicker__header {
  padding-top: 8px;
  padding-bottom: 8px;
}
