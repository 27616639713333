@import "../../assets/sass/index";

.Layout {
  margin-top: $topNav;
  width: 100%;
  position: relative;
  // &.NoScroll {
  //   overflow: hidden;
  //   height: calc(100vh - #{$topNav});
  //   .InnerArea {
  //     overflow: hidden;
  //     height: calc(100vh - #{$topNav});
  //   }
  // }
}
.InnerArea {
  min-height: calc(100vh - #{$topNav} - 19px); // footer
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  box-sizing: border-box;
  padding: $paddingSM 15px $paddingMD;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // media queries
  @include media($sm) {
    max-width: 540px;
    padding: 45px 15px;
  }
  @include media($md) {
    max-width: 720px;
  }
  @include media($lg) {
    max-width: 960px;
  }
  @include media($xl) {
    max-width: 1500px;
  }
}
