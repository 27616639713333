@import "../../assets/sass/index";
.Footer {
  position: relative;
  width: 100%;
  min-height: 20px;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  padding: 0;
  margin: 0;
  transition: 500ms;
  z-index: 99;
  opacity: 1;
  background-color: lighten($cPrimaryLight10, 10%);
  //
  // position: fixed;
  p {
    opacity: 0.8;
    color: $cPrimary10;

    margin: 0;
    text-align: center;
    padding: 2.5px 15px;
    font-size: 0.6em;
    @include media(450) {
      font-size: 0.8em;
    }
  }
}
