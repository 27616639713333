@import "../../assets/sass/index";

.SpinnerArea {
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CenterAbsolute {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
}
.Center {
  margin: auto;
}
.Spinner,
.Spinner:after {
  border-radius: 50%;
  width: 7em;
  height: 7em;
}

.Spinner {
  margin: 15px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.8em solid rgba($cPrimary10, 0.5);
  border-right: 0.8em solid rgba($cPrimary10, 0.5);
  border-bottom: 0.8em solid rgba($cPrimary10, 0.5);
  border-left: 0.8em solid darken($cPrimary10, 0.25);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.Small {
  margin: 0;

  .Spinner,
  .Spinner:after {
    // font-size: 10px;
    margin: 0;
    font-size: 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
}
.Small,
.Small:after {
  border-radius: 50%;
}

.ExtraSmall {
  margin: 0;

  .Spinner,
  .Spinner:after {
    // font-size: 10px;
    margin: 0;
    font-size: 6px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }
}
.ExtraSmall,
.ExtraSmall:after {
  border-radius: 50%;
}
